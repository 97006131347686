<template>
  <div
    :class="`img ${isLogado ? 'img-logado' : 'img-delogado'}`"
    :style="`width: ${width}; height: ${height}; background-image: url('https://ui-avatars.com/api/?name=${usuario.nome}&background=fff&color=000z&bold=true');`"
  />
</template>

<script>
export default {
  name: "UserIcon",
  props: {
    usuario: {
      type: Object,
      default: null,
    },
    isLogado: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "40px",
    },
    height: {
      type: String,
      default: "40px",
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.img {
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  background-size: cover;
  background-position: 25% 25%;
  border: solid;
}
.img-logado {
  border-color: #44b17c;
}
.img-delogado {
  border-color: grey;
}
</style>
