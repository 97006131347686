<template>
  <el-dialog
    title="Precisando de Ajuda?"
    :visible.sync="modalAjuda"
    @close="handleClose"
    width="100%" class="ajuda">
    <p>A equipe da Klaus está sempre a sua disposição para ajudar.</p>
    <p>Esperamos que sua experiência conosco seja a melhor! Caso tenha interesse no software, possua alguma dúvida ou problema entre em contato com o setor responsável. Será um prazer te ajudar!</p>
      <div>
        <h2>
        Contato Vendas
        </h2>
        <ul>
          <li><span>Email:</span> {{emailVendas}}</li>
          <li><span>Telefone:</span> {{telefoneVendas}}</li>
        </ul>
      </div>
      <div>
        <h2>
        Contato Suporte
        </h2>
        <ul>
          <li><span>Email:</span> {{emailSuporte}}</li>
          <li><span>Telefone:</span> {{telefoneSuporte}}</li>
        </ul>
      </div>
      <div>
        <h2>
        Contato Financeiro
        </h2>
        <ul>
          <li><span>Email:</span> {{emailFinanceiro}}</li>
          <li><span>Telefone:</span> {{telefoneFinanceiro}}</li>
        </ul>
      </div>
    </el-dialog>
</template>

<script>

export default {
  name: "modalAjuda",
  props: ["modalAjuda", "handleClose"],
  data() {
    return {
      emailSuporte: "suporte@klausfiscal.com.br",
      emailFinanceiro: "financeiro@klausfiscal.com.br",
      emailVendas: "comercial@klausfiscal.com.br",
      telefoneSuporte: "+55 (11) 4210-7679",
      telefoneFinanceiro: "+55 (11) 4210-7679",
      telefoneVendas: "+55 (11) 4210-7679"
    };
  },
  watch: {
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
