import eventBus from "@/eventBus"
const TutorialUtils = {

    buscaTutoriaisVistos() {
        let tutoriais = null;
        if (localStorage.getItem("tutoriaisVistos")) {
            tutoriais = JSON.parse(localStorage.getItem("tutoriaisVistos"));
        }
        return tutoriais;
    },

    jaViuEssePasso(nomeTutorial, passo) {
        const tutoriais = this.buscaTutoriaisVistos();
        if (tutoriais) {
            return tutoriais[nomeTutorial] && tutoriais[nomeTutorial].includes(passo);
        } else {
            localStorage.setItem("tutoriaisVistos", JSON.stringify({}));
            return false;
        }
    },

    setVisualizacao(nomeTutorial, passos) {
        let tutoriaisAtuais = this.buscaTutoriaisVistos();

        if (!Array.isArray(passos)) {
            passos = [passos];
        }

        for (const passo of passos) {
            if (!this.jaViuEssePasso(nomeTutorial, passo)) {
                if (!tutoriaisAtuais[nomeTutorial]) {
                    tutoriaisAtuais[nomeTutorial] = [];
                }

                tutoriaisAtuais[nomeTutorial].push(passo);
                localStorage.setItem("tutoriaisVistos", JSON.stringify(tutoriaisAtuais));

                setTimeout(() => {
                    eventBus.$emit("passoTutorial", passo);
                }, 0);
            }
        }
    }
};

export default TutorialUtils;
