<template>
  <el-container>
    <!-- ----------------- -->
    <!-- ----- LISTA ----- -->
    <!-- ----------------- -->
    <el-aside :class="`${hasAtivo() ? 'list--aside' : 'list--full-width'}`">
      <SimpleUserList
        ref="sul"
        :completo="true"
        :toggleItemOnClick="true"
        :itemAtivado="sulItemAtivado"
        :itemDesativado="sulItemDesativado"
        :hideOnHover="true"
      />
    </el-aside>

    <!-- --------------------------------------- -->
    <!-- ----- VISUALIZADOR DE INFORMAÇÕES ----- -->
    <!-- --------------------------------------- -->
    <el-main v-if="hasAtivo()">
      <div id="main__usuario">
        <UserIcon
          :usuario="usuarioAtivo"
          :isLogado="isUsuarioAtivoLogado"
          width="80px"
          height="80px"
        />
        <p>{{ usuarioAtivo.nome }}</p>
      </div>

      <dl>
        <!-- --------------------------- -->
        <!-- ----- CONEXÕES ATIVAS ----- -->
        <!-- --------------------------- -->
        <dt class="dt dt-m dt-b">CONEXÕES ATIVAS</dt>
        <dd v-if="hasNotConexao(usuarioAtivo)">Nenhuma conexão ativa</dd>
        <ol v-else>
          <li v-for="(d, j) in usuarioAtivo.devices" :key="j">
            <span>{{ getApp(d.app) }}</span>
            <span> - </span>
            <span>{{ getOS(d.so) }}</span>
          </li>
        </ol>

        <!-- ---------------------------- -->
        <!-- ----- ULTIMA ATIVIDADE ----- -->
        <!-- ---------------------------- -->
        <dt class="dt dt-m dt-b">ULTIMA ATIVIDADE</dt>
        <span v-if="isUltimaAtvNenhuma(usuarioAtivo)">
          <dd>Nenhuma atividade foi realizada por esse usuário</dd>
        </span>
        <span v-else-if="isUltimaAtvSincDfe(usuarioAtivo)">
          <dd>- Tipo: Sincronização</dd>
          <dd>- {{ getDocFiscalUltimaAtvDate(usuarioAtivo) }}</dd>
          <dd>- Em {{ formatUltimaAtvDate(usuarioAtivo) }}</dd>
        </span>

        <!-- ----------------------------------- -->
        <!-- ----- SINCRONIZAÇÕES RECENTES ----- -->
        <!-- ----------------------------------- -->
        <dt class="dt dt-m dt-b">SINCRONIZAÇÕES RECENTES</dt>
        <dd v-if="hasNotAtvRec(usuarioAtivo)">Nenhuma atividade realizada</dd>
        <dd v-else>
          <span v-for="(ar, w) in usuarioAtivo.atividadesRecentes" :key="w">
            <dt class="dt">{{ getDocFiscal(w) }}</dt>
            <span v-if="Object.keys(ar).length === 0">
              <dd>Nenhuma atividade foi realizada com esse documento</dd>
            </span>
            <span v-else>
              <dd>
                <span>Documentos sincronizados: </span>
                <span> {{ ar.quantidadeDeDocumentosSincronizados }} </span>
              </dd>
              <dd>
                <span>Eventos sincronizados: </span>
                <span> {{ ar.quantidadeDeEventosSincronizados }} </span>
              </dd>
              <dd>
                <span>{{ getApp(getAtvReUlSincApp(ar)) }}</span>
                <span> - </span>
                <span> {{ getOS(getAtvReUlSincOS(ar)) }} </span>
                <span> {{ formatDate(ar.ultimaSincronizacao.date) }} </span>
              </dd>
            </span>
          </span>
        </dd>
      </dl>
    </el-main>
  </el-container>
</template>
<script>
import SimpleUserList from "@/components/Konnect/SimpleUserList";
import UserIcon from "@/components/Konnect/UserIcon";
import h from "@/websockets/KWDDPHelper.js";

export default {
  name: "UserList",
  components: { SimpleUserList, UserIcon },
  props: {
    onItemAtivado: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      usuarioAtivo: null,
      isUsuarioAtivoLogado: false,
    };
  },
  methods: {
    // ------------------------------------------------------------------------------------
    // Métodos para Children
    // ------------------------------------------------------------------------------------
    sulItemAtivado(u, isLogado) {
      this.usuarioAtivo = u;
      this.isUsuarioAtivoLogado = isLogado;
      this.onItemAtivado();
    },
    sulItemDesativado() {
      this.usuarioAtivo = null;
    },
    // ------------------------------------------------------------------------------------
    // Métodos Importados
    // ------------------------------------------------------------------------------------
    getApp: h.getAppPresentationName,
    getOS: h.getOSPresentationName,
    isUltimaAtvNenhuma: h.isUltimaAtvNenhuma,
    isUltimaAtvSincDfe: h.isUltimaAtvSincDfe,
    formatUltimaAtvDate: h.formatUltimaAtvDate,
    getDocFiscalUltimaAtvDate: h.getDocFiscalUltimaAtvDate,
    getDocFiscal: h.getDocFiscal,
    formatDate: h.formatDate,

    getAtvReUlSincApp(atividadeRecente) {
      return atividadeRecente.ultimaSincronizacao.aplicativo;
    },
    getAtvReUlSincOS(atividadeRecente) {
      return atividadeRecente.ultimaSincronizacao.sistemaOperacional;
    },

    // ------------------------------------------------------------------------------------
    // Métodos Gerais
    // ------------------------------------------------------------------------------------
    hasAtivo() {
      return !!this.usuarioAtivo;
    },
    ativarItem(item) {
      this.$refs.sul.ativarUsuario(item);
    },
    desativarItem() {
      this.$refs.sul.desativarUsuario();
    },
    // ------------------------------------------------------------------------------------
    // Métodos para lógica dos componentes
    // ------------------------------------------------------------------------------------
    hasNotConexao(usuario) {
      return !usuario.devices || usuario.devices.length === 0;
    },
    hasNotAtvRec(usuario) {
      return !usuario.atividadesRecentes;
    },
  },
};
</script>
<style scoped>
/* ------------------------------------------------------------------------------------*/
/* Alterando componente child */
/* ------------------------------------------------------------------------------------*/
.list--full-width {
  width: 100% !important;
  height: 100% !important;
  margin-left: 10px;
}
.list--aside {
}
.p {
}
.p-l {
  font-size: 10px;
}
.p-m {
  font-size: 13px;
}
.p-b {
  font-weight: bold;
}
.dt {
  margin-top: 10px;
}
.dt-l {
  font-size: 10px;
}
.dt-m {
  font-size: 13px;
}
.dt-b {
  font-weight: bold;
}
#main__usuario {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
