<template>
  <el-drawer
    id="drawer"
    :visible.sync="visible"
    :direction="'rtl'"
    :size="size"
  >
    <!-- ------------------ -->
    <!-- ----- TITULO ----- -->
    <!-- ------------------ -->
    <span id="drawer__title" slot="title">
      <img
        id="drawer__title__img"
        src="../../assets/images/konnect/konnect-logo.svg"
        alt="Logo do Konnect"
      />
      <p class="p p-b">Konnect</p>
    </span>

    <!-- ----------------- -->
    <!-- ----- LISTA ----- -->
    <!-- ----------------- -->
    <ContainerList ref="containerUserList" :onItemAtivado="() => openLarge()" />
  </el-drawer>
</template>

<script>
import ContainerList from "@/components/Konnect/ContainerList";

export default {
  name: "KonnectDrawer",
  components: { ContainerList },
  props: {
    itemAtivo: {
      type: Object,
      default: null,
    },
    onFinishOpen: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      size: "",
      visible: false,
      isStart: false,
      isFinish: false,
    };
  },
  created() {},
  beforeMount() {},
  updated() {
    // O Drawer estiver abrindo...
    if (this.isStart) {
      // Se foi passado um item ativo, ative-o
      if (this.itemAtivo) this.ativarItem(this.itemAtivo);

      // Ao finalizar de atualizar executa o onFinishOpen
      this.onFinishOpen();

      // Atualiza variavel de controle
      this.isStart = false;
    }

    // O Drawer estiver fechando...
    if (this.isFinish) {
      //Desativa o item ativo (se tiver algum)
      this.desativarItem();

      // Atualiza variavel de controle
      this.isFinish = false;
    }
  },
  watch: {
    /**
     * Atualiza as variaveis de auxilio do updated de acordo com a mudança
     * do visible. Esse código é executado antes. O updated só executa quando
     * Os componentes filhos estiverem carregados
     */
    visible: function (novoValor) {
      this.isStart = novoValor;
      this.isFinish = !novoValor;
    },
  },
  methods: {
    // ------------------------------------------------------------------------------------
    // Métodos Gerais
    // ------------------------------------------------------------------------------------

    // ------------------------------------------------------------------------------------
    // Métodos genéricos
    // ------------------------------------------------------------------------------------
    open(size = "500px") {
      this.size = size;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    ativarItem(usuario) {
      this.$refs.containerUserList.ativarItem(usuario);
    },
    desativarItem() {
      this.$refs.containerUserList.desativarItem();
    },

    // ------------------------------------------------------------------------------------
    // Métodos específicos
    // ------------------------------------------------------------------------------------
    isOpen() {
      return this.visible;
    },
    openSmall() {
      this.open();
    },
    openLarge() {
      this.open("100vw");
    },
  },
};
</script>

<style scoped>
/* ------------------------------------------------------------------------------------*/
/* Alterando componente child */
/* ------------------------------------------------------------------------------------*/
/deep/ .el-drawer__body {
  display: flex;
  flex-direction: column;
  height: 0%;
}
/deep/ .el-drawer__body > * {
  height: 100%;
}

/* ------------------------------------------------------------------------------------*/
/* Configurações desse componente */
/* ------------------------------------------------------------------------------------*/
#drawer__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #000;
  margin: 10px;
}

#drawer__title__img {
  height: 40px;
  margin-right: 10px;
}
.p {
}
.p-b {
  font-weight: bold;
}
</style>
