import ServiceAdapter from '@/services/KFisServiceAdapterV1.js'
import AuthUtils from '@/utils/AuthUtils.js'

const KonnectManagerService = {
    permissaoUsuarioLogado: () => {
        return ServiceAdapter.get(`permissaoKonnectEnterprise/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthUtils.getToken()
            }
        })
    },
}

export default KonnectManagerService