<template>
  <div
    v-if="usuario"
    v-click-outside="handleCloseUserbox"
    :class="objTema"
  >
    <el-header class="header">
      <el-row
        class="bar"
        justify="space-around"
      >
         
        <!-- Logo  -->
        <el-col
          :xs="5"
          :md="4"
        >
        <div style="display:flex">
         <div class="header-chat" v-if="windowWidth < 1000">
            <SideSlider
              :nomeUsuarioLogado="nomeUsuarioLogado"
              @logout="logout"
            />
          </div>
          <div>
            <a
              class="userbox__info--interno__link"
              :href="`${recursos.HOME.webapp.baseUrl}${recursos.HOME.rota}`"
            >
              <div id="logo-contabilidade"></div>
            </a>
          </div>
        </div>
        </el-col>

        <!-- Se estiver no Analytics - BOTÃO DASHBOARDS  -->
        <el-col
          class="analytics-button-dashboards"
          v-if="ambienteSelecionado === 'Analytics'"
          :span="4"
        >
          <el-button
            @click="abrirMenuLateralAnalytics"
            type="primary"
          >
            <i class="fas fa-columns"></i>
            <span class="hidden-sm-and-down">
              <span class="hidden-md-and-down">Mostrar</span> Dashboards</span>
          </el-button>
        </el-col>

        <!-- Se estiver na Home - CAMPO DE PESQUISA -->
        <el-col
          v-if="$route.path === '/'"
          :span="6"
          class="search-input hidden-sm-and-down"
        >
          <el-autocomplete
            id="buscaModulos"
            v-model="inputBuscaRecurso"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            placeholder="Busque um módulo, recurso ou ferramenta"
            @select="handleSelecaoRecurso"
            :highlight-first-item="true"
          >
            <i
              slot="suffix"
              class="el-input__icon fas fa-search"
            ></i>
          </el-autocomplete>
        </el-col>

        <!-- KONNECT -->
        <el-col
          :span="1"
          :offset="2"
          class="hidden-sm-and-down"
        >
          <el-tooltip
            v-if="podeConectarAoKWDDP()"
            class="item"
            effect="dark"
            content="Veja quem está online"
            placement="bottom-end"
          >
            <a
              style="z-index: 1000"
              @click="toggleKonnect"
            >
              <img
                v-if="
                  ambienteSelecionado !== 'Analytics' || isTemaClaroConfigurado
                "
                class="top-bar-img"
                src="../../assets/images/konnect/konnect-logo--cinza.svg"
                alt="Logo do Konnect"
              />
              <img
                v-else-if="
                  ambienteSelecionado == 'Analytics' && !isTemaClaroConfigurado
                "
                class="top-bar-img"
                src="../../assets/images/konnect/konnect-logo.svg"
                alt="Logo do Konnect"
              />
            </a>
          </el-tooltip>
        </el-col>

        <!-- SELECT DE AMBIENTE -->
        <el-col
          :xs="4"
          :md="4"
        >
          <el-select
            id="seletorAmbiente"
            class="select-ambiente"
            @change="mudarDeAmbiente"
            v-model="ambienteSelecionado"
            placeholder="Selecione o ambiente"
          >
            <template slot="prefix"><img
                v-if="iconeAtual"
                width="35px"
                class="prefix"
                :src="`${require('../../assets/images/' + iconeAtual)}`"
              /></template>
            <el-option
              class="select-ambiente__option"
              v-for="item in ambientes"
              :key="item.nome"
              :label="item.nome"
              :value="item.nome"
            >
              <img
                :src="`${require('../../assets/images/' + item.icone)}`"
                width="35px"
              />
              <span>{{ item.nome }}</span>
            </el-option>
          </el-select>
        </el-col>

        <!-- USERBOX -->
        <el-col :span="3">
          <div @click="
              () => {
                isMenuUsuarioAberto = !isMenuUsuarioAberto;
              }
            ">
            <div class="userbox">
              <el-avatar :size="50">
                <img
                  width="50"
                  :src="`https://ui-avatars.com/api/?rounded=true&name=${usuario.nome}&background=${corPrincipal}&color=fff`"
                  :alt="getUsername(usuario.nome)"
                />
              </el-avatar>

            </div>
          </div>
        </el-col>
      </el-row>
      <div
        :class="[
          'userbox__abertura',
          {
            'detalhes-conta--escuro':
              !isTemaClaroConfigurado && ambienteSelecionado === 'Analytics',
          },
        ]"
        v-if="isMenuUsuarioAberto"
      >
        <div>
          <div class="userbox__info--interno">
            <div class="userbox__nome">
              <p class="texto-primario texto-primario-truncate">
                {{ getUsername(usuario.nome) }}
              </p>
              <p class="texto-secundario texto-secundario-truncate">{{ usuario.email }}</p>
            </div>
          </div>

          <a
            class="userbox__info--interno__link"
            :href="`${recursos.GERENCIAR_MINHA_CONTA.webapp.baseUrl}${recursos.GERENCIAR_MINHA_CONTA.rota}/meus-dados`"
          ><span class="userbox__info--interno__link"><i class="fas fa-user-cog"></i>Gerenciar minha conta</span></a>
          <a
            class="userbox__info--interno__link"
            :href="`${recursos.GERENCIAR_USUARIOS.webapp.baseUrl}${recursos.GERENCIAR_USUARIOS.rota}`"
            v-if="isRole(roles.ORGANIZACAO)"
          >
            <span class="userbox__info--interno__link"><i class="fas fa-users-cog"></i>Gerenciar usuários</span>
          </a>

          <el-switch
            class="userbox__info--interno__switch"
            v-if="ambienteSelecionado === 'Analytics'"
            v-model="isTemaClaroConfigurado"
            active-color="#01183A"
            inactive-color="#4D8AFB"
            inactive-text="Tema escuro"
            active-text="Tema claro"
          ></el-switch>

          <el-button
            type="text"
            @click="
              () => {
                isMenuUsuarioAberto = false;
                logout();
              }
            "
          >
            <span class="userbox__info--interno__link">
              <i class="fas fa-sign-out-alt"></i> Sair
            </span>
          </el-button>
        </div>
      </div>
    </el-header>
    <el-divider
      v-if="ambienteSelecionado !== 'Analytics'"
      class="header-divider"
      direction="horizontal"
    />

    <KonnectDrawer
      ref="konnectDrawer"
      v-if="!isRole(roles.DESENVOLVEDOR) && !isRole(roles.USUARIO_LIMITADO)"
    />
  </div>
</template>

<script>
import "@/assets/css/theme.css";
import eventBus from "@/eventBus";
import "@/components/BI/analyticsTheme.css";
import AuthUtils from "@/utils/AuthUtils.js";
import TutorialUtils from "@/tutorials/TutorialUtils";
import KWDDPHelper from "@/websockets/KWDDPHelper.js";
import KonnectDrawer from "@/components/Konnect/Drawer";
import ModalAjuda from "@/components/UserBar/ModalAjuda";
import recursosKlausConfig, { recursos } from "@/assets/recursosKlausConfig";
import SideSlider from "../../components/Konnect/SideSlider.vue";
export default {
  name: "UpperBar",
  components: {
    ModalAjuda,
    KonnectDrawer,
    SideSlider,
  },
  data() {
    return {
      usuario: AuthUtils.getUser(),
      isCollapse: false,
      roles: AuthUtils.roles,
      modalAjuda: false,
      corPrincipal: "",
      podeConectarAoKWDDP: KWDDPHelper.podeConectarAoKWDDP,
      inputBuscaRecurso: "",
      recursos,

      ambienteSelecionado: recursos.HOME.nome,
      ambienteSelecionadoAnterior: recursos.HOME.nome,
      iconeAtual: "",

      isMenuUsuarioAberto: false,

      windowWidth: window.innerWidth,

      // Opção relacionada ao Analytics
      isTemaClaroConfigurado: true,
    };
  },
  async created() {
    // Define o ambiente atual
    this.definirAmbienteAtual();

    // Recupera o id da organização do usuário logado
    await AuthUtils.validarUsuarioLogado();
    let orgId = null;
    if (!this.usuario?.organizacao) {
      orgId = this.usuario.id;
    } else {
      orgId = this.usuario.organizacao.id;
    }

    // Busca logo da organização
    var link = document.createElement("link");
    link.href = `${process.env.VUE_APP_KFIS_SERVICE_AZURE_STORAGE}temanuvemcontainer/${orgId}.css`;
    link.type = "text/css";
    link.rel = "stylesheet";
    document.querySelector("head").appendChild(link);
  },

  watch: {
    "$route.path": {
      immediate: false,
      handler() {
        this.definirAmbienteAtual();
      },
    },

    ambienteSelecionado(_, valorAntigo) {
      this.ambienteSelecionadoAnterior = valorAntigo;
      this.corPrincipal = this.getCorPrincipal();
    },

    isTemaClaroConfigurado(novoValor) {
      this.$store.dispatch("setTema", novoValor);
    },
  },

  mounted() {
    setTimeout(() => {
      this.corPrincipal = this.getCorPrincipal();
    }, 1000);

    eventBus.$on("toggleKonnect", () => {
      this.toggleKonnect();
    });

    this.iniciarTutorial();

    eventBus.$on("passoTutorial", (ultimoPasso) => {
      if (ultimoPasso === "configuracoesGerenciamento") {
        this.iniciarTutorial();
      }
    });

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    
  },

  computed: {
    objTema() {
      const nomeTema = this.isTemaClaroConfigurado ? "claro" : "tema-escuro";

      const obj = {
        [nomeTema]: this.ambienteSelecionado === "Analytics",
      };

      return obj;
    },

    ambientes() {
      return recursosKlausConfig.filter(
        (r) => r.isAmbiente && this.haPermissoes(r)
      );
    },
     nomeUsuarioLogado() {
      return JSON?.parse(window?.localStorage?.getItem("usuario"))?.nome;
    },
  },

  methods: {
    isRole: AuthUtils.isRole,

    haPermissoes(recurso) {
      return this.usuario?.roles.some(
        (userRole) =>
          recurso?.roles.includes(userRole) ||
          recurso?.roles.includes(this.usuario.tipoUsuario)
      );
    },

    getCorPrincipal() {
      return this.ambienteSelecionado === "Analytics"
        ? "000820"
        : window
            .getComputedStyle(document.body)
            .getPropertyValue("--primeira-cor")
            .substring(2);
    },

    definirAmbienteAtual() {
      let rotaAtual = this.$route.path;

      if (rotaAtual !== "/") {
        const tokens = rotaAtual.slice(1).split("/");
        rotaAtual = `/${tokens[0]}`;
      }

      let objAmbienteSelecionado = this.ambientes.find(
        (ambiente) =>
          ambiente.webapp.id === `${process.env.VUE_APP_WEBAPP_ID}` &&
          ambiente.rota === `${rotaAtual}`
      );

      if (!objAmbienteSelecionado) {
        return this.$router.push("/").catch(() => {});
      }

      // Seleciona o ambiente atual
      this.ambienteSelecionado = objAmbienteSelecionado?.nome;

      // Força a atualização
      this.$forceUpdate();

      // Atualiza o item anterior para página atual
      this.$nextTick(() => {
        this.ambienteSelecionadoAnterior = objAmbienteSelecionado?.nome;
      });

      // Seleciona o icone
      this.iconeAtual = objAmbienteSelecionado?.icone;
    },

    mudarDeAmbiente(valorNovo) {
      // Pega o valor anterior
      let valorAntigo = this.ambienteSelecionadoAnterior;

      // Se a nova rota não existir, só retorna
      if (valorAntigo === null) return;

      // Busca o recursos
      valorNovo = recursosKlausConfig.find((x) => x.nome === valorNovo);
      valorAntigo = recursosKlausConfig.find((x) => x.nome === valorAntigo);

      // Se for a mesma rota, só retorna
      if (valorAntigo.id === valorNovo.id) return;

      // Se a rota for no mesmo site, faz a mudança de página
      if (valorNovo.webapp.id === valorAntigo?.webapp.id)
        return this.$router.push(valorNovo.rota).catch(() => {});

      // Se a nova rota em um outro website, monta a url e muda de site
      window.location.replace(`${valorNovo.webapp.baseUrl}${valorNovo.rota}`);
    },

    querySearch(queryString, cb) {
      if (queryString.trim().length > 2) {
        const recursosFiltrados = recursosKlausConfig.filter((r) => {
          return (
            this.haPermissoes(r) &&
            r.nome.toLowerCase().includes(queryString.toLowerCase())
          );
        });

        cb(
          recursosFiltrados.map((r) => {
            return {
              value: r.nome,
              link: r.rota,
            };
          })
        );
      }
    },

    handleSelecaoRecurso(valor) {
      if (valor.link.includes("http")) {
        window.open(valor.link, "_blank");
      } else {
        this.$router.push(valor.link);
      }
    },

    getUsername(name) {
      return name.split(" ")[0];
    },
    changeActive() {
      this.isActive = !this.isActive;
    },
    toggleKonnect() {
      this.$refs.konnectDrawer.isOpen()
        ? this.$refs.konnectDrawer.close()
        : this.$refs.konnectDrawer.openSmall();
    },
    handleCollapse() {
      this.isCollapse = !this.isCollapse;
      window.localStorage.setItem(
        "menu-collapse",
        JSON.stringify(this.isCollapse)
      );
    },
    mudarTema(val) {
      this.$store.dispatch("setTema", !val);
    },

    abrirMenuLateralAnalytics() {
      eventBus.$emit("abrirMenuLateralAnalytics");
    },

    handleCloseUserbox() {
      if (this.isMenuUsuarioAberto) {
        this.isMenuUsuarioAberto = false;
      }
    },

    iniciarTutorial() {
      // registrando quais campos o tutorial automático vai guiar
      if (
        this.ambienteSelecionado === "Home" &&
        TutorialUtils.jaViuEssePasso(
          "paginaInicial",
          "configuracoesGerenciamento"
        ) &&
        !TutorialUtils.jaViuEssePasso("paginaInicial", "buscaModulos") &&
        window.outerWidth &&
        window.outerWidth >= 800
      ) {
        const steps = ["buscaModulos", "seletorAmbiente"];

        this.$tutorial.addSteps(steps);
        this.$tutorial.start();
      }
    },

    logout() {
      this.usuario = null;
      AuthUtils.doLogout();
      window.location.reload();
    },

    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
};
</script>

<style scoped>
>>> .el-input__inner {
  font-family: "Inter", sans-serif !important;
}
.el-input__inner {
  /* border-: 1px solid #dcdfe6; */
}

>>> .userbox__info--interno .userbox__nome * {
  text-align: left !important;
}

.el-header {
  padding: 0 !important;
  border-top-width: 0px;
}

#logo-contabilidade {
  background-size: contain !important;
  width: 220px;
}

.header {
  height: 70px !important;
  display: flex;
  min-width: 300px;
  width: 90vw;
  margin: 0 auto;
}

/* HAMBURGUER MENU */
>>> .bm-item-list > * {
  align-items: center;
}

>>> .header-chat .bm-burger-button {
  height: 15px;
  width: 20px;
  left: 15px;
  top: 27px;
}

>>> .header-chat .bm-burger-bars {
  background-color: #0060b3;
}

>>> .header-chat .line-style {
  border-radius: 2px;
}

>>> .header-chat .bm-item-list {
  margin: 0;
}

>>> .header-chat .bm-menu {
  border-top-right-radius: 10px;
  background-color: #fff;
  padding: 0;
}
>>> .header-chat .bm-cross {
  background-color: #fff;
}

>>> .header-chat .bm-cross-button {
  margin-right: 10px;
}

>>> .header-chat .menu-header {
  background-color: var(--primeira-cor);
  height: 100px;
  margin-bottom: 15px;
}

>>> .fa-comment-alt,
>>> .c {
  color: var(--primeira-cor);
  font-size: 17px;
  margin-right: 10px;
}

>>> .header-chat span {
  font-weight: 600;
  font-size: 17px;
  color: #03283f;
}

.header-divider {
  margin: 0;
}

.bar {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  justify-content: flex-end;
}

.bar div:first-child {
  margin-right: auto;
}

.analytics-button-dashboards {
  margin-left: 10vw;
}

@media (max-width: 768px) {
  #logo-contabilidade {
    background-size: auto 80%;
    width: 100px;
  }

  .header {
    min-width: 300px;
    margin: 0 auto;
  }

  .bar {
    margin-left: 0;
    width: 100vw;
    justify-content: space-around;
  }

  .bar div:first-child {
    margin-right: 0;
  }

  .analytics-button-dashboards {
    margin-left: 0;
  }
}
.search-input >>> .el-autocomplete {
  width: 100%;
}

.search-input >>> .el-autocomplete input {
  height: 50px !important;
  border-radius: 4px !important;
}

>>> .el-input__inner:hover {
  border-color: #c0c4cc !important;
}

>>> .el-input__inner {
  border: 1px solid #dcdfe6 !important;
}

>>> .el-input__inner:focus {
  border-color: #14538c !important;
  outline: 0 !important;
}

>>> .el-input__inner {
  border-radius: 5px !important;
}

>>> .header {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.select-ambiente >>> .el-input__inner {
  border-radius: 5px;
  height: 50px;
}

.select-ambiente__option.el-select-dropdown__item {
  height: 45px;
  display: flex;
  align-items: center;
}

.select-ambiente__option.el-select-dropdown__item img {
  margin-right: 5px;
}

.select-ambiente >>> .el-input__prefix {
  top: 8px;
}

.select-ambiente >>> .el-input__inner {
  padding-left: 50px;
}

.userbox {
  max-width: 210px;
  display: flex;
  margin-left: auto;
  justify-content: space-around;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: auto;
}

.userbox__nome p {
  margin: 0;
  font-size: 14px;
}

.userbox__info--interno .userbox__nome p {
  word-break: break-word;
}

.userbox__info--interno .userbox__nome {
  width: 100%;
}

.userbox__abertura {
  position: absolute;
  top: 8vh;
  right: 3vw;
  display: block;
  width: 18vw;
  min-width: 300px;
  border-radius: 5px;
  z-index: 100000000;
  background: white;
  padding: 30px 20px;
  box-shadow: -4px 10px 5px 2px rgb(0 0 0 / 9%);
}

.userbox__abertura a {
  padding: 10px 0;
}

.userbox__abertura * {
  display: block;
}

.userbox__info--interno {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  min-width: 150px;
  margin-bottom: 10px;
}

.userbox__info--interno__link {
  display: flex;
  width: 200px;
}

.userbox__info--interno__link i {
  width: 20px;
  margin-right: 15px;
}

.userbox__info--interno__switch {
  margin: 10px 0;
}

.userbox__abertura .el-button {
  margin-top: 10px;
}

.userbox__info--interno__switch + .el-button {
  margin-top: 30px;
}

.truncate {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item {
  margin-right: 15px;
}

.item img {
  width: 30px;
}

.item.notify {
  color: #7d7d7d;
}

.item:hover {
  cursor: pointer;
}
</style>
