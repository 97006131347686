<template>
  <div id="external-div">
    <Scrollbar
      v-if="usuariosOnline.length > 0 || usuariosOffline.length > 0"
      :style="{ height: alturaMaxima ? `${alturaMaxima}px` : 'auto' }"
      id="scrollbar"
    >
      <span v-for="(u, i) in usuariosOnline" :key="`online-${i}`">
        <UserCard
          :usuario="u"
          :isLogado="true"
          :completo="completo"
          :hideOnHover="hideOnHover"
          :isAtivo="isAtivo"
          :onUsuarioClick="onUsuarioClick"
          :trocarUsuario="trocarUsuario"
          :ativarUsuario="ativarUsuario"
          :desativarUsuario="desativarUsuario"
        />
      </span>
      <span v-for="(u, i) in usuariosOffline" :key="`offline-${i}`">
        <UserCard
          :usuario="u"
          :isLogado="false"
          :completo="completo"
          :hideOnHover="hideOnHover"
          :isAtivo="isAtivo"
          :onUsuarioClick="onUsuarioClick"
          :trocarUsuario="trocarUsuario"
          :ativarUsuario="ativarUsuario"
          :desativarUsuario="desativarUsuario"
        />
      </span>
    </Scrollbar>
    <div class="empty-result" v-else>
      <p>Ainda não há conexões</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { default as Scrollbar } from "vue-perfect-scrollbar";
import UserCard from "@/components/Konnect/UserCard";

export default {
  name: "SimpleUserList",
  components: { Scrollbar, UserCard },
  props: {
    completo: {
      type: Boolean,
      default: true
    },
    hideOnHover: {
      type: Boolean,
      default: false
    },
    onItemClick: {
      type: Function,
      default: (usuario = null) => {}
    },
    toggleItemOnClick: {
      type: Boolean,
      default: false
    },
    itemAtivado: {
      type: Function,
      default: (usuario = null) => {}
    },
    itemDesativado: {
      type: Function,
      default: () => {}
    },
    alturaMaxima: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      usuarioAtivo: null
    };
  },
  computed: {
    ...mapGetters({
      usuariosOnline: "getOutrosUsuariosOnlineAsArray",
      usuariosOffline: "getOutrosUsuariosOfflineAsArray"
    })
  },
  methods: {
    // ------------------------------------------------------------------------------------
    // Métodos Gerenciamento de lista e item ativo
    // ------------------------------------------------------------------------------------
    onUsuarioClick(usuario, isLogado) {
      // Executa o click do usuário
      this.onItemClick(usuario);

      // Se não foi passado para dar toggle, simplesmente encerra o método
      if (!this.toggleItemOnClick) return;

      // Verifica se o usuário está ativo
      const eOUsuarioAtivo = this.isAtivo(usuario);

      // Desativa, e se o usuário que foi clicado é o ativo
      // Então ativa o usuário que foi clicado
      if (eOUsuarioAtivo) {
        this.desativarUsuario();
      } else {
        this.trocarUsuario(usuario, isLogado);
      }
    },
    isAtivo(usuario) {
      return usuario === this.usuarioAtivo;
    },
    trocarUsuario(novoUsuario, isLogado) {
      this.usuarioAtivo = null;
      this.ativarUsuario(novoUsuario, isLogado);
    },
    ativarUsuario(usuario, isLogado) {
      this.usuarioAtivo = usuario;
      this.itemAtivado(usuario, isLogado);
    },
    desativarUsuario() {
      this.usuarioAtivo = null;
      this.itemDesativado();
    }
  }
};
</script>

<style scoped>
#external-div {
  width: 100%;
  height: 100%;
}

#scrollbar {
  height: 100%;
  overflow: hidden;
  font-size: 0.95rem;
}

.empty-result {
  text-align: center;
}
</style>
