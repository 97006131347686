<template>
  <el-popover trigger="hover" :disabled="hideOnHover">
    <el-card
      :class="`card ${isAtivo(usuario) ? 'item--ativo' : 'item--desativo'}`"
      v-on:click.native="onUsuarioClick(usuario, isLogado)"
      slot="reference"
    >
      <div class="item">
        <UserIcon :usuario="usuario" :isLogado="isLogado" />
        <div class="item__data" v-if="completo">
          <p class="p p-m p-b">{{ usuario.nome }}</p>
          <p class="p p-m">
            <span>Conectado em</span>
            <span class="p-b">{{ sizeDevices(usuario) }} </span>
            <span class="p-b" v-if="igt1Devices(usuario)">aplicativos</span>
            <span class="p-b" v-else>aplicativo</span>
          </p>
          <p class="p p-l">
            <span>Ultima Atividade:</span>

            <!-- Ainda não fez nada -->
            <span v-if="isUltimaAtvNenhuma(usuario)">
              Nenhuma atividade foi realizada por esse usuário
            </span>

            <!-- Sincronizou documentos -->
            <span v-else-if="isUltimaAtvSincDfe(usuario)">
              <span>Sincronização de</span>
              <span>{{ getDocFiscalUltimaAtvDate(usuario) }}</span>
              <span>({{ formatUltimaAtvDate(usuario) }})</span>
            </span>
          </p>
        </div>
      </div>
    </el-card>

    <!-- ---------------- -->
    <!-- ---- Popover --- -->
    <!-- ---------------- -->
    <div id="popover">
      <dl>
        <dt class="dt dt-m dt-b">CONEXÕES ATIVAS</dt>
        <dd v-if="!isLogado">Nenhum dispositivo conectado</dd>
        <ol v-else>
          <li v-for="(d, j) in usuario.devices" :key="j">
            <span>{{ getAppPresentationName(d.app) }}</span>
            <span> - </span>
            <span>{{ getOSPresentationName(d.so) }}</span>
          </li>
        </ol>
      </dl>
    </div>
  </el-popover>
</template>

<script>
import h from "@/websockets/KWDDPHelper.js";
import UserIcon from "@/components/Konnect/UserIcon";
export default {
  name: "UserCard",
  components: { UserIcon },
  props: {
    usuario: {
      type: Object,
      default: {},
    },
    completo: {
      type: Boolean,
      default: true,
    },
    isLogado: {
      type: Boolean,
      default: false,
    },
    hideOnHover: {
      type: Boolean,
      default: false,
    },
    isAtivo: {
      type: Function,
      default: () => {},
    },
    onUsuarioClick: {
      type: Function,
      default: () => {},
    },
    trocarUsuario: {
      type: Function,
      default: () => {},
    },
    ativarUsuario: {
      type: Function,
      default: () => {},
    },
    desativarUsuario: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    // ------------------------------------------------------------------------------------
    // Métodos Importados
    // ------------------------------------------------------------------------------------
    getAppPresentationName: h.getAppPresentationName,
    getOSPresentationName: h.getOSPresentationName,
    isUltimaAtvNenhuma: h.isUltimaAtvNenhuma,
    isUltimaAtvSincDfe: h.isUltimaAtvSincDfe,
    formatUltimaAtvDate: h.formatUltimaAtvDate,
    getDocFiscalUltimaAtvDate: h.getDocFiscalUltimaAtvDate,

    // ------------------------------------------------------------------------------------
    // Métodos específicos
    // -----------------------------------------------------------------------------------
    doesNotHaveDevices(usuario) {
      return !usuario || !usuario.devices || usuario.devices === 0;
    },
    igt1Devices(u) {
      if (this.doesNotHaveDevices(u)) return false;
      return u.devices.length > 1;
    },
    sizeDevices(u) {
      if (this.doesNotHaveDevices(u)) return 0;
      return u.devices.length;
    },
  },
};
</script>

<style scoped>
/* ------------------------------------------------------------------------------------*/
/* Alterando componente child */
/* ------------------------------------------------------------------------------------*/
/deep/ .el-card__body {
  padding: 0px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* ------------------------------------------------------------------------------------*/
/* Configurações desse componente */
/* ------------------------------------------------------------------------------------*/
.item--ativo {
  background-color: #c9c9c9;
}
.item-desativo {
  background-color: #ffffff;
}
.item__data {
  margin-left: 10px;
}
.card {
  cursor: pointer;
  border: none;
  box-shadow: none;
}
.item {
  display: flex;
  align-items: center;
}
.p {
}
.p-l {
  font-size: 10px;
}
.p-m {
  font-size: 13px;
}
.p-b {
  font-weight: bold;
}
#popover {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 10px;
}
.dt {
}
.dt-m {
  font-size: 13px;
}
.dt-b {
  font-weight: bold;
}

@media (max-width: 1240px) {
  .item {
    flex-wrap: wrap;
  }
}
</style>
