export default {
  // Chat
  SET_CHAT: "SET_CHAT",
  SET_CHAT_MSGS: "SET_CHAT_MSGS",
  CLEAN_CHAT_MSGS: "CLEAN_CHAT_MSGS",
  SET_DEVE_NAVEGAR_ATE_MENSAGEM: "SET_DEVE_NAVEGAR_ATE_MENSAGEM",
  CLEAN_DEVE_NAVEGAR_ATE_MENSAGEM: "CLEAN_DEVE_NAVEGAR_ATE_MENSAGEM",

  // KMSX
  KMSX_CLEAR: "kmsxClear",
  KMSX_ADICIONAR_TODOS_USUARIOS_ONLINE: "kmsxAlterarTodosUsuariosLogados", // action: /conexoes/device/usuariosConectados
  KMSX_ADICIONAR_DEVICE_ONLINE: "kmsxAdicionarDevice",// action: /conexoes/device/conectou
  KMSX_REMOVER_DEVICE_ONLINE: "kmsxRemoverDevice", // action: /conexoes/device/desconectou
  KMSX_ADICIONAR_USUARIO_ONLINE: "kmsxAdicionarUsuario",// action: /conexoes/usuario/conectou
  KMSX_REMOVER_USUARIO_ONLINE: "kmsxRemoverUsuario",// action: /conexoes/usuario/desconectou
  KMSX_ATUALIZAR_TODOS_USUARIOS_OFFLINE: "kmsxAtualizarTodosOffline",

  // Loading
  PAGINA_CARREGANDO: "paginaCarregando",
};
