<template>
  <div id="app">
    <UserBar
      id="userBar"
      v-if="rotaUsaNavbar && showUserBar"
    />
    <router-view />
  </div>
</template>


<script>
import eventBus from "./eventBus";
import { Notification } from "element-ui";
import AuthUtils from "@/utils/AuthUtils.js";
import UserBar from "@/components/UserBar/UserBar.vue";

let countChangeStatus = 0;

export default {
  components: { UserBar },
  data() {
    return {
      showUserBar: !window.matchMedia("(display-mode: standalone)").matches,
    };
  },
  computed: {
    rotaUsaNavbar() {
      const rotasSemUserbar = ["/login"];
      const caminho = this.$route.path;

      return (
        caminho !== "/" &&
        rotasSemUserbar.every((rota) => !caminho.includes(rota))
      );
    },
  },
  async created() {
    // Se estiver exibindo a barra superior, só retorna
    if (this.showUserBar) return;

    // Se o usuário não estiver logado, só retorna
    if (!AuthUtils.getUser()) return;

    // Caso contrário...
    // Recupera o id da organização do usuário logado
    await AuthUtils.validarUsuarioLogado();
    const usuario = AuthUtils.getUser();
    let orgId = null;
    if (usuario?.organizacao) {
      orgId = usuario.organizacao.id;
    } else {
      orgId = usuario.id;
    }

    // Busca logo da organização
    var link = document.createElement("link");
    link.href = `${process.env.VUE_APP_KFIS_SERVICE_AZURE_STORAGE}temanuvemcontainer/${orgId}.css`;
    link.type = "text/css";
    link.rel = "stylesheet";
    document.querySelector("head").appendChild(link);
  },
  mounted() {
    eventBus.$on("tratarConexaoWebsocket", (status) =>
      this.tratarConexaoWebsocket(status)
    );
  },
  methods: {
    tratarConexaoWebsocket(status) {
      // Aumenta variavel de controle
      countChangeStatus++;

      Notification.closeAll();
      switch (status) {
        case "Ok":
          if (countChangeStatus !== 1)
            Notification.success({
              title: "Conexão estabelecida",
              message: "O aplicativo está pronto para o uso.",
            });
          break;
        case "Retrying":
          Notification.warning({
            title: "Perda de conexão",
            message:
              "Tentando restabelecer conexão, tente novamente em instantes.",
            duration: 0,
          });
          break;
        case "Failed":
          Notification.error({
            showClose: false,
            title: "Perda de conexão",
            message:
              "Atualize a página, caso o erro persistir entre em contato com o suporte da Klaus.",
            duration: 0,
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
#app {
  height: 100vh !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
}

#userBar {
  flex-grow: 0 !important;
  max-height: 20vh !important;
}

#app,
.el-message {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
</style>
